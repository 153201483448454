<template>
  <div class="AlertMessage" :type="type" v-bind="bodyAttr">
    <Spacer num="1" />
    <div class="AlertMessageIcon" v-if="icon">
      <iconic :name="icon" />
    </div>
    <Spacer v-if="icon" num="2" />
    <div class="AlertMessageText" v-html="message"></div>
    <Spacer num="1" />
  </div>
</template>

<script>
export default {
  props: ["message", "icon", "type", "bodyAttr"],
};
</script>

<style lang="scss" scoped>
.AlertMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .AlertMessageText {
    text-align: center;
    font-size: 120%;
  }
  .AlertMessageIcon {
    font-size: 350%;
    display: flex;
    justify-content: center;
  }
  &[type="success"] .AlertMessageIcon {
    color: #4bb71b;
  }
  &[type="error"] .AlertMessageIcon {
    color: #ff0000;
  }
}
</style>
